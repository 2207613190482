body {
  margin: 0;
  font-family: "proxima_nova_rgregular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4D4D4D;
}

code {
  font-family: "proxima_nova_rgregular" !important;
}

@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("./assets/fonts/proxima_nova_regular.eot");
  src: url("./assets/fonts/proxima_nova_regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/proxima_nova_regular.woff2") format("woff2"),
    url("./assets/fonts/proxima_nova_regular.woff") format("woff"),
    url("./assets/fonts/proxima_nova_regular.ttf") format("truetype"),
    url("./assets/fonts/proxima_nova_regular.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("./assets/fonts/proxima_nova_bold.eot");
  src: url("./assets/fonts/proxima_nova_bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/proxima_nova_bold.woff2") format("woff2"),
    url("./assets/fonts/proxima_nova_bold.woff") format("woff"),
    url("./assets/fonts/proxima_nova_bold.ttf") format("truetype"),
    url("./assets/fonts/proxima_nova_bold.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
