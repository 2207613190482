$font-size-base: 0.875rem;
$line-height-base: 1.1;
$body-bg: #4d4d4d;

$theme-colors: (
	"body": #f4f4f4,
	"primary": #1f7873,
	"black": #1b1b1b,
	"greywhite": #dadce6,
	"white": #ffffff,
	"darkgrey": #1f7873,
	"lightgrey": #898989,
	"titlegray": #6a6f80,
	"gray": #f1f1f1,
	"dark-slate-gray": #4b4e58,
	"bold-gray": #52575a,
	"bold-black": #0a001f,
	"light-blue": #ecf3fd,
	"green": #529968,
	"red": #d74a49,
	"filbody": #f5f4f9,
	"table-title": #c2f0f0,
	"table-font": #000000,
	"trolleygrey": #808080,
	"blue": #007bff,
	"dark":#264F73,
	"greenish":#13c2c2,
);
