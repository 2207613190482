.header {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
	padding: 0;
	background-color: #fff;
	color: #000;
	line-height: 50px;
	height: 50px;
	z-index: 1030;
}

.menu-text {
	font-size: 14px;
	color: #6a6f80;
}
.bell-out {
	font-size: 1.5rem;
}
.user-out {
	font-size: 1.5rem;
	padding-right: 10px;
}
.f-12 {
	font-size: 12px;
}
.sider-box {
	position: absolute;
	height: calc(100vh - 50px);
	z-index: 1031;
	width: 200px;
	box-shadow: 7px 0 16px 0px rgba(0, 0, 0, 0.1);
}
.content-style {
	overflow: auto;
	overflow-x: hidden;
	// min-height: 100vh;
	height: calc(100vh - 50px);
	background: #f5f4f9;
}
.ant-menu-item {
	color: #6a6f80;
	font-size: 14px;
	font-weight: 500;
}

.ant-layout-header .ant-menu {
	line-height: 48px;
}

// Footer style
.ant-layout-footer {
	color: #333333;
	font-size: 12px;
	padding: 11px 10px;
	background-color: #e4e4e4;
}

.ant-notification {
	z-index: 1031;
}

@media screen and (min-width: 1680px) {
	.container-xxl {
		max-width: 1400px;
		margin: 0 auto;
	}
}
