@import "./scss/customize-bootstrap.scss";
@import "bootstrap/scss/bootstrap.scss";

@each $key, $value in $theme-colors {
	.bg-#{$key}-bar {
		background-color: $value;
	}
}

.App {
	text-align: center;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.ant-picker-input input::placeholder {
	font-size: 0.8rem;
	color: var(--black);
}

.ant-picker-suffix {
	color: var(--black);
	opacity: 0.75;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.scroll {
	box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.24);
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.code-box-demo {
	text-align: center;
}
.ant-layout-header,
.ant-layout-footer {
	color: #fff;
	background: #6aa0c7;
}
[data-theme="dark"] .ant-layout-header {
	background: #6aa0c7;
}
[data-theme="dark"] .ant-layout-footer {
	background: #6aa0c7;
}
.ant-layout-footer {
	line-height: 1.5;
}
.ant-layout-sider {
	color: #fff;
	line-height: 120px;
	background: #fff;
}
[data-theme="dark"] .ant-layout-sider {
	background: #fff;
}
.ant-layout-content {
	// min-height: 20px;
	color: rgb(41, 37, 37);
	// line-height: 70px;
	background: #fff;
}
[data-theme="dark"] .ant-layout-content {
	background: #fff;
}
> .code-box-demo > .ant-layout + .ant-layout {
	margin-top: 48px;
}
.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #fff;
}

.logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}

.card-no-padding {
	.ant-card-body {
		padding: 0px;
	}
}

// header style
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
	color: #1d7874;
	border-bottom: 2px solid #1d7874;
}

// Salse Cards title
.font-title {
	.ant-card-head-title {
		font-size: 18px;
		font-weight: bold;
	}
}

.f-8 {
	font-size: 8px;
}
.f-10 {
	font-size: 10px;
}
.f-12 {
	font-size: 12px;
}
.f-14 {
	font-size: 14px;
}
.f-16 {
	font-size: 16px;
}
.f-18 {
	font-size: 18px;
}
.f-24 {
	font-size: 24px;
}
.font-nova-bold {
	font-family: "proxima_nova_rgbold";
}

.filter-box-wrapper {
	.ant-select-multiple .ant-select-selector {
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		.ant-select-selection-item {
			.ant-select-selection-item-remove {
				display: none;
			}
		}
	}
	.ant-select-arrow {
		width: 16px;
		height: 16px;
		font-size: 16px;
		color: #000;
		top: 43%;
	}
}

.ant-select-tree-switcher {
	display: none;
}

.ant-select-tree .ant-select-tree-checkbox {
	margin: 4px 0px 0 3px;
}

.ant-select-tree-checkbox-inner {
	color: var(--primary);
	font: normal normal normal 18px/1 FontAwesome;
	font-size: 18px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
	background: none;
	border: none;
}

.ant-select-tree-checkbox-inner:after,
.ant-select-tree-checkbox-checked::after {
	display: none !important;
}

.ant-select-tree-checkbox-inner:before {
	content: "\f096";
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:before {
	content: "\f046";
}

.ant-select-multiple .ant-select-selection-placeholder {
	font-size: 0.8rem;
	left: 7px;
	opacity: 1;
	color: var(--black);
}

.drop-down-wrapper {
	position: relative;
	&.hide-drop-down .ant-tree-select-dropdown {
		display: none;
	}
	.ant-tree-select-dropdown {
		z-index: 2000;
		top: 40px !important;
	}

	.ant-select-multiple .ant-select-selection-item-remove {
		display: none;
	}

	.ant-select-arrow {
		font-size: 18px;
		color: var(--black);
		line-height: 30px;
		height: 100%;
		margin-top: 0;
		top: 0;
		right: 5px;
		vertical-align: middle;
		display: inline-block;
		font-family: FontAwesome;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		&:after {
			content: "\f0d7";
		}
		.anticon {
			display: none;
		}
	}
	.ant-select-multiple.ant-select-disabled {
		.ant-select-selector {
			background-color: #ffffff;
			.ant-select-selection-item span {
				color: var(--greywhite) !important;
			}
			.ant-select-selection-placeholder {
				color: var(--greywhite) !important;
			}
			&:after {
				display: none;
			}
		}
	}
	.ant-select-multiple .ant-select-selection-item {
		position: relative;
		flex: none;
		overflow: auto;
		text-overflow: inherit;
		padding-left: 7px;
		margin-right: 0;
		padding-right: 0;
		background: none;
		border: none;
		border-radius: unset;
	}
	.ant-select-multiple .ant-select-selection-item:first-child {
		padding-left: 3px;
	}
	.ant-select-multiple .ant-select-selection-item span {
		margin: 0;
		color: var(--black) !important;
		font-size: 0.8rem;
		padding-top: 2px;
	}
	.ant-select-multiple .ant-select-selection-item:first-child:before {
		display: none;
	}
	.ant-select-multiple .ant-select-selection-item:before {
		position: absolute;
		left: 0;
		bottom: 2px;
		display: inline-block;
		content: ", ";
	}
	.ant-select-selection-search {
		display: none;
	}
	.ant-select-multiple .ant-select-selector {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow: hidden;
		justify-content: flex-start;
		min-height: 30px;
		border: none;
	}
	.ant-select-multiple .ant-select-selector:after {
		display: block;
		content: " ";
		position: absolute;
		height: 100%;
		width: 20px;
		right: 0;
		top: 0;
		background: #ffffff;
	}
}

.ant-select-tree-node-content-wrapper {
	color: var(--black) !important;
	font-size: 0.8rem;
}

// Recharts tootltip style
.recharts-tooltip-wrapper {
	z-index: 1999;
}
.recharts-default-tooltip {
	background-color: rgba(#000, 0.75) !important;
	border: 1px solid transparent !important;
	border-radius: 5px;
}

p.recharts-tooltip-label {
	color: #fff;
}

li.recharts-tooltip-item {
	color: #fff !important;
}
// Recharts tootltip style

.border-red {
	border: 1px solid #ff7f7f !important;
}

/** Style for the antd calender style **/
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
	background: var(--greywhite);
}

.ant-picker-week-panel-row-selected td:last-child {
	border-radius: 0 5px 5px 0;
}

.ant-picker-week-panel-row-selected td:nth-child(2) {
	border-radius: 5px 0 0 5px;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
	background: var(--primary);
	border-radius: 50%;
	transform: scale(1);
	color: #ffffff;
	overflow: visible;
	z-index: 2;
}

.ant-picker-year-panel td .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner {
	color: var(--primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: var(--greywhite) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	display: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
	font-size: 1.1rem;
	font-weight: bold;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
	color: #1890ff;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
	color: var(--greywhite) !important;
}

.ant-picker-header {
	background: var(--primary);
	color: #fff;
}

.ant-picker-header button {
	color: #fff;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: var(--primary);
	color: #fff !important;
	border-radius: 5px !important;
	transform: scale(1.2);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: var(--greywhite);
}

.ant-picker-range .ant-picker-active-bar {
	background: var(--primary);
}

/** Style for the antd calender style **/
.ant-picker-range-wrapper {
	.ant-picker-range-arrow {
		display: none;
	}
	.ant-picker-panel-container {
		display: flex;
		.ant-picker-panels {
			order: 2;
			flex-direction: column;
			max-height: 100vh;
			overflow: auto;
			& .ant-picker-panel + .ant-picker-panel {
				display: none;
			}
			.ant-picker-header-prev-btn,
			.ant-picker-header-super-prev-btn,
			.ant-picker-header-next-btn,
			.ant-picker-header-super-next-btn {
				visibility: visible !important;
			}
		}
		.ant-picker-footer {
			order: 1;
			min-width: auto;
		}
	}
}

.vom-week-range-filter {
	.ant-picker-input {
		input {
			z-index: 2;
			//   opacity: 0;
		}
	}
	&:hover {
		.ant-picker-suffix {
			margin-left: 20px;
		}
	}
	.ant-picker-clear {
		right: 20px;
		.anticon {
			vertical-align: middle;
		}
	}
}

.ant-picker-header > button:hover {
	color: white;
}

.ant-picker:hover,
.ant-picker:focus,
.ant-picker-focused {
	border: 1px solid transparent;
	outline: none;
}

.ant-radio-checked .ant-radio-inner {
	border-color: var(--primary);
}

.ant-radio-inner::after,
.ant-radio-input:hover {
	background-color: var(--primary);
}

.rangeDateInput {
	.ant-picker {
		.ant-picker-input {
			input {
				text-align: center;
			}
		}
	}
}

.regional-card-ref {
	.ant-card-body {
		padding: 12px;
	}
}
.btn-ref {
	.ant-radio-button-wrapper {
		width: 94px;
	}
	.ant-radio-button-wrapper:first-child {
		border-left: 1px solid #d9d9d9;
	}
	.ant-radio-button-wrapper:hover {
		position: relative;
		color: #fff;
		background-color: #1d7874;
	}
	.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
		border-right-color: #d9d9d9;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		background: transparent;
	}
	.ant-radio-button-wrapper-checked {
		position: relative;
		color: #fff;
		background-color: #1d7874;
		border-color: #d9d9d9 !important;
	}
}
.bar-card-body-ref {
	.ant-card-body {
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
	stroke-opacity: 0;
}

#icon-inactive {
	fill: "%00000" !important;
	background-image: url("./assets/images/graph_inactive.svg");
	height: 50px;
	width: 50px;
	background-repeat: no-repeat !important;
}
#icon-active {
	fill: "%99001" !important;
	background-image: url("./assets/images/graph_inactive.svg");
	height: 50px;
	width: 50px;
	background-repeat: no-repeat !important;
}

.custom-tab {
	background-color: #fff;
	.ant-tabs-nav {
		width: 180px;
		.ant-tabs-nav-wrap {
			background-color: #fff;
			padding: 0px 10px 10px 0;
			.ant-tabs-nav-list {
				.ant-tabs-tab.ant-tabs-tab-active {
					background-color: #13c2c2 !important;
					.ant-tabs-tab-btn {
						color: #fff;
					}
				}
			}
		}
	}
}

.card-no-border {
	.ant-card-head {
		border-bottom: none;
		border-radius: 0;
	}
}
a.bg-greenish:hover,
a.bg-greenish:focus,
button.bg-greenish:hover,
button.bg-greenish:focus {
	background-color: #13c2c2 !important;
	border: 1px solid transparent;
}
